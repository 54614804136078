import _ from "lodash"

const state = {
    data: [],
    pageSize: 10,
    currentPage: 1,
    searchField: "", // 当前搜索的字段
    searchKey: ''// 搜索的key
}

const mutations = {
    ADD_BATCH_DATA(state, payload) { // 批量添加数据
        state.data = _.unionWith(state.data, payload, (a, b) => {
            return JSON.stringify(a) === JSON.stringify(b); // 使用 JSON.stringify 生成每个对象的字符串形式，用于比较
        });
        console.log(state.data,'state.data ')
        // localStorage.setItem('returnserviceOrderData', JSON.stringify(state.data))
    },
    REMOVE_DATA(state, id) { // 删除数据
        state.data = state.data.filter(item => item.id !== id)
    },
    UPDATE_DATA(state, newData) { // 更新数据
        const index = state.data.findIndex(item => item.id === newData.id)
        if (index !== -1) {
            state.data.splice(index, 1, newData)
        }
    },
    SET_CURRENT_PAGE(state, page) { // 设置当前页码
        state.currentPage = page
    },
    SET_SEARCH_FIELD(state, field) { // 设置搜索字段
        state.searchField = field
    },
    SET_SEARCH_KEY(state, searchKey) { // 设置搜索字段
        state.searchKey = searchKey
    },
}

const actions = {
    addBatchData({ commit, dispatch }, newDataList) { // 批量添加数据并去重
        dispatch('loading/setLoading', true, {root: true})
        commit("ADD_BATCH_DATA", newDataList)
        dispatch('loading/setLoading', false, {root: true})

    },
    removeData({ commit, dispatch }, id) { // 删除数据
        dispatch('loading/setLoading', true, {root: true})
        commit("REMOVE_DATA", id)
        dispatch('loading/setLoading', false, {root: true})
    },
    updateData({ commit, dispatch }, newData) { // 更新数据
        dispatch('loading/setLoading', true, {root: true})
        commit("UPDATE_DATA", newData)
        dispatch('loading/setLoading', false, {root: true})

    },
    setCurrentPage({ commit, dispatch }, page) { // 设置当前页码
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_CURRENT_PAGE", page)
        dispatch('loading/setLoading', false, {root: true})

    },
    setSearchField({ commit, dispatch }, field) { // 设置搜索字段
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_SEARCH_FIELD", field)
        dispatch('loading/setLoading', false, {root: true})
    },
    setSearchKey({ commit, dispatch }, searchKey) { // 设置搜索字段
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_SEARCH_KEY", searchKey)
        dispatch('loading/setLoading', false, {root: true})
    },
}

const getters = {
    pageData: (state) => { // 获取分页后的数据
        const start = (state.currentPage - 1) * state.pageSize
        return getters.searchedData(state).slice(start, start + state.pageSize)
    },
    totalPage: (state) => { // 获取总页数
        console.log(Math.ceil(getters.searchedData(state).length / state.pageSize),'222')
        return Math.ceil(getters.searchedData(state).length / state.pageSize)
    },
    total: (state) => { // 获取总页数
        return getters.searchedData(state).length
    },
    pageSize: (state) => {
        return state.pageSize
    },
    allData: (state) => {
        return state.data
    },
    searchedData: (state) => { // 获取经过搜索筛选后的数据
        const { searchField,searchKey } = state
        if (!searchField) {
            return state.data
        }
        const keyword = searchField.toLowerCase()
        return _.filter(state.data, (item) => {
            const value = item[searchKey]
            return value && value.toString().toLowerCase().includes(keyword)
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}