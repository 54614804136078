import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

import returnserviceOrder from './returnserviceOrder';
import loading from './loading';
import siteInformationQuery from './siteInformationQuery.js'
import assetsManagement from './assetsManagement.js'

const store = new Vuex.Store({
    modules: {
        returnserviceOrder,
        loading,
        siteInformationQuery,
        assetsManagement
    },
  });
export default store
