import _ from "lodash"

const state = {
    data: [],
    pageSize: 10,
    currentPage: 1,
    searchField: "", // 当前搜索的字段
    searchKey: ''// 搜索的key
}

const mutations = {
    ADD_BATCH_DATA(state, payload) { // 批量添加数据
        // state.data = _.unionWith(state.data, payload, (a, b) => {
        //     return JSON.stringify(a) === JSON.stringify(b); // 使用 JSON.stringify 生成每个对象的字符串形式，用于比较
        // });
        // localStorage.setItem('returnserviceOrderData', JSON.stringify(state.data))
        state.data = [
            {
                "源节点名称": "23-46083-苏州中环中新大道-数据",
                "业务接入接口名称": "14-TPA1EG24-8:1",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN五期/园区PTN五期7900环"
            },
            {
                "源节点名称": "23-46083-苏州中环中新大道-数据",
                "业务接入接口名称": "14-TPA1EG24-10:1",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN五期/园区PTN五期7900环"
            },
            {
                "源节点名称": "3-2110-苏州吴中区澜调国际-室分-PROJECT",
                "业务接入接口名称": "5-TPK1EM8F-1:1(苏州-园区-澜调国际LD（M）-HLW-HW-LTE)",
                "所属子网路径": "根子网/苏州移动SPN/苏州移动/骨干汇聚对8（中环沈浒路-中环金鸡湖大道）/100G接入汇聚环3"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "6-EG4F-3(香泾桥-HW-LTE-宏站)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "5-EG2-1(联通胥口箭泾村1号站-HW-宏站-LTE)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "1-EG2-2(胥口中心卫生院L-宏站-HW-LTE)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "2-EG2-2(苏州凌青电子-HW-宏站-LTE)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "6-EG4F-2(胥口惠民汽车维修-HW-LTE-宏站)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-31311-苏州凌青电子-基站",
                "业务接入接口名称": "6-EG4F-1(格宝产业园-HW-LTE-宏站)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN三期/苏州移动PTN三期吴中区一"
            },
            {
                "源节点名称": "23-6999-苏州南洲-数据",
                "业务接入接口名称": "6-81EFG4-3(室分-苏州南洲02F-OLT001-HW-MA5680T-16-H801ETHB-0)",
                "所属子网路径": "根子网/苏州移动PTN/市区PTN四期/苏州移动PTN四期园区二"
            },
            {
                "源节点名称": "3-593-苏州张家港后塍建材城-汇聚",
                "业务接入接口名称": "4-TPA1EY16-1:1",
                "所属子网路径": "根子网/苏州移动SPN/苏州移动/骨干汇聚对28（张家港保税区南环-张家港悦丰大厦）/100G接入汇聚环7"
            },
            {
                "源节点名称": "3-639-常熟福懋-汇聚-PROJECT",
                "业务接入接口名称": "4-TPA1EY16-5:1(苏州_常熟_福懋_CRNR(H)E4-ALX-5G)",
                "所属子网路径": "根子网/苏州移动SPN/苏州移动/骨干汇聚对21（常熟梅李二-常熟天主堂）/100G接入汇聚环3"
            }
        ]
    },
    REMOVE_DATA(state, id) { // 删除数据
        state.data = []
    },
    UPDATE_DATA(state, newData) { // 更新数据
        const index = state.data.findIndex(item => item.id === newData.id)
        if (index !== -1) {
            state.data.splice(index, 1, newData)
        }
    },
    SET_CURRENT_PAGE(state, page) { // 设置当前页码
        state.currentPage = page
    },
    SET_CURRENT_PAGE(state, page) { // 设置当前页码
        state.currentPage = page
    },
    SET_SEARCH_FIELD(state, field) { // 设置搜索字段
        state.searchField = field
    },
    SET_SEARCH_KEY(state, searchKey) { // 设置搜索字段
        state.searchKey = searchKey
    },
}

const actions = {
    addBatchData({ commit, dispatch }, newDataList) { // 批量添加数据并去重
        dispatch('loading/setLoading', true, {root: true})
        commit("ADD_BATCH_DATA", newDataList)
        dispatch('loading/setLoading', false, {root: true})

    },
    removeData({ commit, dispatch }, id) { // 删除数据
        dispatch('loading/setLoading', true, {root: true})
        commit("REMOVE_DATA", id)
        dispatch('loading/setLoading', false, {root: true})

    },
    updateData({ commit, dispatch }, newData) { // 更新数据
        dispatch('loading/setLoading', true, {root: true})
        commit("UPDATE_DATA", newData)
        dispatch('loading/setLoading', false, {root: true})
    },
    setCurrentPage({ commit, dispatch }, page) { // 设置当前页码
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_CURRENT_PAGE", page)
        dispatch('loading/setLoading', false, {root: true})
    },
    setSearchField({ commit, dispatch }, field) { // 设置搜索字段
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_SEARCH_FIELD", field)
        dispatch('loading/setLoading', false, {root: true})
    },
    setSearchKey({ commit, dispatch }, searchKey) { // 设置搜索字段
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_SEARCH_KEY", searchKey)
        dispatch('loading/setLoading', false, {root: true})
    },
}

const getters = {
    pageData: (state) => { // 获取分页后的数据
        const start = (state.currentPage - 1) * state.pageSize
        return getters.searchedData(state).slice(start, start + state.pageSize)
    },
    totalPage: (state) => { // 获取总页数
        console.log(Math.ceil(getters.searchedData(state).length / state.pageSize),'222')
        return Math.ceil(getters.searchedData(state).length / state.pageSize)
    },
    total: (state) => { // 获取总页数
        return getters.searchedData(state).length
    },
    pageSize: (state) => {
        return state.pageSize
    },
    allData: (state) => {
        return state.data
    },
    searchedData: (state) => { // 获取经过搜索筛选后的数据
        const { searchField,searchKey } = state
        if (!searchField) {
            return state.data
        }
        const keyword = searchField.toLowerCase()
        return _.filter(state.data, (item) => {
            const value = item[searchKey]
            return value && value.toString().toLowerCase().includes(keyword)
        })
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

