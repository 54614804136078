import { Loading } from 'element-ui';
import _ from 'lodash/debounce';

const types = {
  SET_LOADING: 'SET_LOADING'
};
export default {
  namespaced: true,
  state: {
    // 记录loading状态
    requestLoading: 0,
    // 当前路由请求数组
    ReqPendingArr: []
  },
  getters: {
    requestLoading (state) {
      return state.requestLoading;
    }
  },
  mutations: {
    [types.SET_LOADING] (state, { flag, target }) {
      const loadingOptions = {
        text: '拼命加载中',
        spinner: 'el-icon-loading',
        target: target || 'body',
        // customClass: isIframe ? 'epc-loading' : 'deepwatch-loading'
      };
      const loadingInstance = Loading.service(loadingOptions);
      flag ? ++state.requestLoading : --state.requestLoading;
      state.requestLoading === 0 && _(() => {
        state.requestLoading === 0 && loadingInstance.close();
      }, 300)();
    },
    pushReqPending (state, payload) {
      state.ReqPendingArr.push(payload.reqPendingFun);
    },
    clearReqPending ({ ReqPendingArr }) {
      if (ReqPendingArr.length) {
        ReqPendingArr.forEach(item => {
          item('路由跳转取消上一个路由的请求');
        });
        ReqPendingArr = [];
      }
    }
  },
  actions: {

    /**
     * 设置loading
     * @param {*} param0
     * @param {*} flag
     * @param {*} target
     */

    setLoading ({ commit }, flag, target) {
      const {
        SET_LOADING
      } = types;
      commit(SET_LOADING, { flag, target });
    }
  }
};
