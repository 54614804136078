import _ from "lodash"

const state = {
    data: [],
    header: [],
    pageSize: 10,
    currentPage: 1
}

const mutations = {
    ADD_BATCH_DATA(state, payload) { // 批量添加数据
        console.log(this.$store)
        state.data = _.unionWith(payload,state.data, (a, b) => {
            return JSON.stringify(a) === JSON.stringify(b); // 使用 JSON.stringify 生成每个对象的字符串形式，用于比较
        });
        // localStorage.setItem('returnserviceOrderData', JSON.stringify(state.data))
    },
    REMOVE_DATA(state) { // 删除数据
        state.data = []
    },
    UPDATE_DATA(state, newData) { // 更新数据
        const index = state.data.findIndex(item => item.id === newData.id)
        if (index !== -1) {
            state.data.splice(index, 1, newData)
        }
    },
    SET_CURRENT_PAGE(state, page) { // 设置当前页码
        state.currentPage = page
    },
    SET_HEADER (state, header) { // 设置当前页码
        state.header = header
    },
}

const actions = {
    addBatchData({ commit, dispatch }, newDataList) { // 批量添加数据并去重
        dispatch('loading/setLoading', true, {root: true})
        commit("ADD_BATCH_DATA", newDataList)
        dispatch('loading/setLoading', false, {root: true})

    },
    removeData({ commit,dispatch }, id) { // 删除数据
        dispatch('loading/setLoading', true, {root: true})
        commit("REMOVE_DATA")
        dispatch('loading/setLoading', false, {root: true})
    },
    updateData({ commit,dispatch }, newData) { // 更新数据
        dispatch('loading/setLoading', true, {root: true})
        commit("UPDATE_DATA", newData)
        dispatch('loading/setLoading', false, {root: true})


    },
    setCurrentPage({ commit,dispatch }, page) { // 设置当前页码
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_CURRENT_PAGE", page)
        dispatch('loading/setLoading', false, {root: true})
    },
    setHeader ({ commit,dispatch }, header) {
        dispatch('loading/setLoading', true, {root: true})
        commit("SET_HEADER", header)
        dispatch('loading/setLoading', false, {root: true})
    },
}

const getters = {
    pageData: (state) => { // 获取分页后的数据
        const start = (state.currentPage - 1) * state.pageSize
        return state.data.slice(start, start + state.pageSize)
    },
    totalPage: (state) => { // 获取总页数
        console.log(Math.ceil(state.data.length / state.pageSize))
        return Math.ceil(state.data.length / state.pageSize)
    },
    total: (state) => { // 获取总页数
        return state.data.length
    },
    pageSize: (state) => {
        return state.pageSize
    },
    allData: (state) => {
        return state.data
    },
    header: (state) => {
        return state.header
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}