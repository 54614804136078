import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/home/index.vue'),
      redirect: '/returnserviceOrder',
      children: [
        {
          path: 'returnserviceOrder',
          name: 'returnserviceOrder',
          component: () => import('@/views/returnserviceOrder/index.vue'),
        },
        {
          path: 'siteInformationQuery',
          name: 'siteInformationQuery',
          component: () => import('@/views/siteInformationQuery/index.vue'),
        },
        {
          path: 'assetsManagement',
          name: 'assetsManagement',
          component: () => import('@/views/assetsManagement/index.vue'),
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
    },
    { path: '*', redirect: '/' },
  ],
});

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token')
  console.log(token)
  if (token) {
    if (to.name === 'login') {
      next({ name: 'index' });
    } else {
      next()
    }
  } else {
    if (to.name !== 'login') {
      next({ name: 'login' });
    } else {
      next()
    }
  }
});

// 后置路由守卫
router.afterEach((to, from) => {
});
export default router;
